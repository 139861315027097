import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "blog-container col-12 col-sm-6 col-lg-4 flex-centered" }
const _hoisted_2 = { class: "post-img w-100 h-100 d-flex justify-content-center align-item-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "blog-text pb-2 pt-3 px-4 d-flex flex-column justify-content-start align-items-center text-center" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "blog-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: {name: 'BlogPost', params: {'slug': _ctx.slug, lang: _ctx.$route.params.lang}},
      class: "blog-box d-flex justify-content-center align-items-center flex-column"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.image ? `${_ctx.uploadPath}/${_ctx.image}` : `/img/no-load.png`,
            alt: "Blog preview image"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["category-tabs mx-2 mb-3", {'selected-category': _ctx.selectedCategories.includes(category.id)}]),
                key: category.id,
                style: _normalizeStyle(`background-color:#${category.hexValue};`)
              }, null, 6))
            }), 128))
          ]),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.heading), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}