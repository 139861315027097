import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d51cf0d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-0 flex-centered" }
const _hoisted_2 = { class: "d-flex flex-column flex-lg-row align-items-center" }
const _hoisted_3 = { class: "img-container col-3 col-sm-2 align-self-start" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "px-0 px-md-4 d-flex justify-content-center justify-content-md-start col-12 col-lg-10" }
const _hoisted_6 = { class: "text-container col-11 col-md-9 col-lg-7 col-xxl-6 ms-0 ms-md-2" }
const _hoisted_7 = {
  key: 0,
  class: "mb-2 mb-sm-3 h-md-secondary text-start text-uppercase"
}
const _hoisted_8 = {
  key: 1,
  class: "h1 mb-2 mb-sm-3 h-md-secondary text-start text-uppercase"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 2,
  class: "gap-2 d-flex justify-content-center justify-content-md-start"
}
const _hoisted_11 = {
  type: "button",
  class: "btn-md-secondary px-4 me-md-2 mt-2 text-uppercase"
}
const _hoisted_12 = { class: "d-flex justify-content-center w-100 h-100 position-absolute top-0 img-wrap p-0" }
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-fluid generic-banner-container w-100 d-flex p-0 py-5 py-xxl-3 relative", [{'unique-generic-banner-height': _ctx.uniqueHeight},{'generic-banner-full-height': _ctx.keepHeight}]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.floatingImg,
            class: "m-0 p-0 mb-3 mb-lg-0",
            alt: "",
            loading: "lazy"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.isH1)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_7, _toDisplayString(_ctx.heading), 1))
              : (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(_ctx.heading), 1)),
            _createElementVNode("div", {
              innerHTML: _ctx.text,
              class: "p-md-primary"
            }, null, 8, _hoisted_9),
            (_ctx.buttonText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    to: { name: _ctx.buttonLink, params: {lang: _ctx.$route.params.lang} }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.buttonText), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("img", {
        src: _ctx.imageSrc,
        alt: "Banner image"
      }, null, 8, _hoisted_13)
    ])
  ], 2))
}