import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47913330"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "item-content-container position-absolute col-12 text-center h-100" }
const _hoisted_3 = { class: "d-flex col-12 flex-wrap align-items-center align-content-center h-100 p-3" }
const _hoisted_4 = {
  key: 0,
  class: "col-12 col-md-6 featured-img flex-centered"
}
const _hoisted_5 = { class: "col-12 col-md-10 col-xxl-9 img-container p-3 p-lg-0" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["carousel-banner-container bg-primary", {'large': _ctx.section == 'Carousel 1', 'above-design-now': _ctx.aboveDesignNowButton}])
  }, [
    (_ctx.carouselItems.length)
      ? (_openBlock(), _createBlock(_component_Splide, {
          key: 0,
          class: "carousel-banner",
          options: _ctx.spliderConfig,
          ref: `carouselSlide${_ctx.section.replace(' ', '-')}`
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselItems, (item) => {
              return (_openBlock(), _createBlock(_component_SplideSlide, {
                key: item.id
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "col-12 item-container d-flex position-relative",
                    style: _normalizeStyle(`background-color:#${item.backgroundColour};`)
                  }, [
                    (item.backgroundImageUrl)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: `${_ctx.uploadPath}/${item.backgroundImageUrl}`,
                          class: "img-fluid",
                          alt: "Carousel item"
                        }, null, 8, _hoisted_1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (item.featuredImageUrl)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("img", {
                                  sclass: "img-fluid ",
                                  src: `${_ctx.uploadPath}/${item.featuredImageUrl}`,
                                  alt: "Carousel Image"
                                }, null, 8, _hoisted_6)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: _normalizeClass([item.featuredImageUrl ? 'col-12 col-md-6 br-md-none' : 'col-12 align-items-center br-lg-none', "d-flex flex-column"])
                        }, [
                          _createElementVNode("h2", {
                            innerHTML: _ctx.formattedMarkdown(item.title),
                            style: _normalizeStyle(`color:#${item.titleFontColour};`),
                            class: _normalizeClass(["title col-12 p-0 home-page-heading", item.featuredImageUrl ? 'text-center text-md-start' : 'text-center'])
                          }, null, 14, _hoisted_7),
                          _createElementVNode("p", {
                            style: _normalizeStyle(`color:#${item.contentFontColour};`),
                            class: _normalizeClass(["content p-0 home-page-body", item.featuredImageUrl ? 'text-center text-md-start col-12 col-md-11 col-xl-10 col-xxl-9' : 'text-center col-12 col-md-10 col-xxl-8']),
                            innerHTML: _ctx.formattedMarkdown(item.content)
                          }, null, 14, _hoisted_8),
                          (item.buttonText && item.buttonUrl)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(item.featuredImageUrl ? 'text-center text-md-start' : 'text-center')
                              }, [
                                _createVNode(_component_router_link, {
                                  class: "btn mt-sm-2 btn-light",
                                  to: { path: item.buttonUrl, params: {lang: _ctx.$route.params.lang}},
                                  style: _normalizeStyle(`color:#${item.buttonFontColour}; background-color:#${item.buttonBackgroundColour};border-color:#${item.buttonBackgroundColour};`)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.buttonText), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to", "style"])
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 2)
                      ])
                    ])
                  ], 4)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"]))
      : _createCommentVNode("", true)
  ], 2))
}